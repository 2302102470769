import revive_payload_client_IOnqPb9e0g from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IIKz8eAJb2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WltP0dBz3i from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ElH5IKrkOI from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.4.2_rollup@4.21.3_vite@4.5.3_@types+node@18.19.31_ter_3zrqxlbm6rdl2llkdmk5u7vzp4/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_LHTF9gx8qb from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_FWgEGMFtsV from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_yiSUXT84RX from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.5_rollup@4.21.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_SYLInqmrlI from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.2.3_@bugsnag+core@7.19.0_rollup@4.21.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import shopware_plugin_txRT4f0fRY from "/vercel/path0/apps/storefront/.nuxt/runtime/shopware.plugin.mjs";
import plugin_sTRJmP5YNl from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@5.7.10_rollup@4.21.3/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_eo0ngqFy86 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_vyUypyVQYy from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import active_campaign_client_94LcNpk3D3 from "/vercel/path0/apps/storefront/src/plugins/active-campaign.client.ts";
import clarity_client_G0skVuoIDj from "/vercel/path0/apps/storefront/src/plugins/clarity.client.ts";
import cookiebot_client_2XRNXQo76P from "/vercel/path0/apps/storefront/src/plugins/cookiebot.client.ts";
import pinterest_client_yR7n6LWTH8 from "/vercel/path0/apps/storefront/src/plugins/pinterest.client.ts";
import trengo_client_Slo7HE1gBd from "/vercel/path0/apps/storefront/src/plugins/trengo.client.ts";
export default [
  revive_payload_client_IOnqPb9e0g,
  unhead_IIKz8eAJb2,
  router_WltP0dBz3i,
  _0_siteConfig_ElH5IKrkOI,
  payload_client_LHTF9gx8qb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FWgEGMFtsV,
  plugin_client_yiSUXT84RX,
  plugin_SYLInqmrlI,
  shopware_plugin_txRT4f0fRY,
  plugin_sTRJmP5YNl,
  chunk_reload_client_eo0ngqFy86,
  check_outdated_build_client_vyUypyVQYy,
  active_campaign_client_94LcNpk3D3,
  clarity_client_G0skVuoIDj,
  cookiebot_client_2XRNXQo76P,
  pinterest_client_yR7n6LWTH8,
  trengo_client_Slo7HE1gBd
]