import { useSessionContext as _coreFunctionality } from "@shopware-pwa/composables-next";
import { createSharedComposable } from "@vueuse/core";
import { SHIPPING_METHODS_LIST } from "~/lib/constants";


const _useSessionContext = () => {
  const coreFunctionality = _coreFunctionality();
  const { sessionContext } = coreFunctionality
  
  const selectedShippingMethod = computed<KippieShippingMethod | undefined>(() => {
		if (!sessionContext.value) return;

		const shippingMethod = SHIPPING_METHODS_LIST.find(
			(method) => method.id === sessionContext.value!.shippingMethod?.id
		);
		if (!shippingMethod) return;

		return {
			...sessionContext.value.shippingMethod as KippieShippingMethod,
			...shippingMethod
		};
	});

  return {
    ...coreFunctionality,
    selectedShippingMethod,
		setShippingMethod: coreFunctionality.setShippingMethod as (method: Partial<KippieShippingMethod>) => void,
  }
}

export const useSessionContext = createSharedComposable(_useSessionContext);