<script setup lang="ts">
import { EmptyMessage } from "#components";
import type { Story } from "~/types/storyblok-generic";

type MessageDictionary = {
	[code: number]: string;
};

type CustomError = {
	url: string;
	statusCode: number;
	statusMessage: string;
	message: string;
	description: string;
	data?: any;
};

const error = useError();
const currentError: CustomError = unref(error) as CustomError;

console.error(currentError);

const handleError = () => {
	clearError({
		redirect: "/"
	});
};

const customMessageDictionary: MessageDictionary = {
	404: "De kip is gevlogen! Gebruik het menu bovenin om de weg terug naar het kippenhok te vinden! (Pagina niet gevonden)",
	408: "Onze grill loopt even vast... Geen zorgen, probeer het zo nog een keertje!",
	500: "De grill is oververhit en heeft wat rook gemaakt! Onze chef-kip is het aan het oplossen. Probeer het zo opnieuw!",
	503: "Het kippenhok is even gesloten voor onderhoud of we hebben te veel kuikens online. Probeer het straks weer!"
};

const storyblokApi = useStoryblokApi();
const { data: story } = await useAsyncData("error", async (): Promise<Story> => {
	const { data } = await storyblokApi.get(`cdn/stories/error`, {});

	return data.story;
});

const route = useRoute();
watch(route, () => {
	clearError();
});
</script>
<template>
	<div>
		<NuxtLayout>
			<Container class="py-8">
				<EmptyMessage
					v-if="currentError"
					:title="`Oeps ${currentError?.statusCode ?? ''}!`"
					:message="
						(currentError.statusCode ?? 500) in customMessageDictionary
							? customMessageDictionary[(currentError.statusCode ?? 500)]
							: currentError.statusMessage
					"
				>
					<KippieButton class="self-start" size="small" @click="handleError">
						Terug naar homepagina
					</KippieButton>
				</EmptyMessage>
			</Container>
			<StoryblokComponent v-if="story" :blok="story.content!" />
		</NuxtLayout>
	</div>
</template>
