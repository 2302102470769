import type { AsyncDataOptions } from "nuxt/app";
import type { KeysOf } from "nuxt/dist/app/composables/asyncData";


/**
 * Composable for using useAsyncData with getCachedData function provided to make 
 * client side caching in nuxt payload possible, in client rendered and static mode.
*/
export const useCachedAsyncData = <
	ResT,
	DataE = Error,
	DataT = ResT,
	PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
	DefaultT = null
>(
	key: string,
	resolver: () => Promise<ResT>,
	options?: Omit<AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>, "getCachedData">
) => {
	const nuxtApp = useNuxtApp()

	// accessing nuxt payload
	const { data } = useNuxtData<ResT>(key);

	return useAsyncData<ResT, DataE, DataT, PickKeys, DefaultT>(key, resolver, {
		...options,
		getCachedData() {
			// getting cached data from nuxt payload
			if(nuxtApp.isHydrating && data.value){
				return data.value
			}

			// getting cached data from static payload
			if(nuxtApp.static.data[key]){
				return nuxtApp.static.data[key]
			}

			return null
		}
	});
};


export const preloadAsyncData = async (key: string, resolver: () => Promise<any>) => {
	const { data } = useNuxtData(key);

	if (data.value) return;

	data.value = await resolver();
};
