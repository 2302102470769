import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/vercel/path0/apps/storefront/src/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@parcel+watcher@2.4.1_@types+node@18.19.31_magicast@0.3.5_optionator@0.9.3_rollup@_eu4lw4ozoysfssqgmxx4qutzya/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/apps/storefront/src/middleware/auth.ts"),
  "cart-filled": () => import("/vercel/path0/apps/storefront/src/middleware/cartFilled.ts"),
  guest: () => import("/vercel/path0/apps/storefront/src/middleware/guest.ts"),
  reviews: () => import("/vercel/path0/apps/storefront/src/middleware/reviews.ts")
}