<script setup lang="ts">
import { DEPOSIT_PRODUCT_IDS } from "./lib/constants";
import { getProduct } from "@shopware-pwa/api-client";

const { public: publicConfig } = useRuntimeConfig();
const { refreshSessionContext } = useSessionContext();
const { refreshCart } = useCart();

// Fallback SEO tags.
const title = "Welkom bij Kippie.";
const description =
	"Welkom bij Kippie. Bekijk ons assortiment, de laatste weekacties en bestel de lekkerste producten om af te halen in een van onze winkels of om thuis te laten bezorgen!";
const ogImage = `${publicConfig.siteUrl}/og-image.png`;

useSeoMeta({
	titleTemplate: (titleChunk) => (titleChunk ? `Kippie.nl  - ${titleChunk}` : "Kippie.nl"),
	description,
	ogTitle: title,
	ogDescription: description,
	ogImage,
	ogSiteName: "Kippie",
	ogLocale: "nl_NL",
	twitterTitle: title,
	twitterImage: ogImage,
	twitterCard: "summary_large_image"
});

const { apiInstance } = useShopwareContext();


onMounted(async () => {
	refreshSessionContext();
	refreshCart();

	DEPOSIT_PRODUCT_IDS.forEach((id) => {
		preloadAsyncData(`shopware:product:${id}`, async () => (await getProduct(id, {}, apiInstance)).product);
	});
});

defineRobotMeta();
</script>

<template>
	<div>
		<NuxtLayout>
			<NuxtLoadingIndicator color="repeating-linear-gradient(to right,#FFD932 0%,#FFE781 50%,#FFD932 100%)" />
			<NuxtPage :keepalive="{
				max: 30
			}" />
			<SnackbarProvider />
			<DrawerProvider />
			<LazyFullScreenSearch />
			<LazyShoppingCartSidebar />
		</NuxtLayout>
	</div>
</template>
