import { useCart as _coreFunctionality } from "@shopware-pwa/composables-next";
import { createSharedComposable } from "@vueuse/core";

const _useCart = () => {
	const coreFunctionality = _coreFunctionality();
	const { apiInstance } = useShopwareContext();
	const { open } = useShoppingCartView();
	const { selectedShippingMethod } = useSessionContext();

	const cart = coreFunctionality.cart as ComputedRef<KippieCart> // type casting for kippie specific extensions in cart

	const errors = computed(() => cart.value?.errors || {});

	const hasCrititalErrors = computed(() => Object.values(errors.value).some((error) => error.level === 20));

	const selectedPickupLocation = computed<KippieLocation | undefined>(
		() => cart.value?.extensions?.pickupLocationData as KippieLocation
	);

	const timeslot = computed(() => cart.value?.extensions?.deliveryTimeSlot);

	// @TODO: Check need for watcher
	watch(
		[selectedShippingMethod, selectedPickupLocation],
		async ([newMethod, newLocation], [oldMethod, oldLocation]) => {
			if (
				(!!oldMethod && newMethod?.id !== oldMethod.id) ||
				(!!oldLocation && newLocation?.id !== oldLocation.id)
			) {
				updateExtension({
					deliveryTimeSlot: null
				});
			}
		}
	);

	const updateExtension = async (extension: any) => {
		await apiInstance.invoke.post("/store-api/checkout/cart", extension);
		coreFunctionality.refreshCart();
	};

	const addProduct = async (params: { id: string; quantity?: number }) => {
		await coreFunctionality.addProduct(params);

		if (Object.keys(errors.value).length) {
			open();
		}
	};

	return {
		...coreFunctionality,
		addProduct,
		cart,
		hasCrititalErrors,
		selectedPickupLocation,
		timeslot,
		updateExtension
	};
};

export const useCart = createSharedComposable(_useCart);
